.CatMap-count {
  font-size: 9px;
}

.marker-cluster-small {
  background-color: rgba(218, 94, 94, 0.6);
}

.marker-cluster-small div {
  background-color: rgba(226, 36, 36, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}

.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}

.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

.CatMap-FireMarker {
  font-size: 25px;
  background-color: transparent;
}
